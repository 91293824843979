import React from 'react';

import { useTranslation } from "react-i18next";

import logo from '../img/white.png'

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#2050A0] z-10 relative max-w-[1280px] mx-auto rounded-[30px] pr-[100px] mt-[80px] xl:mt-[160px]'>
      <div className='flex flex-col xl:flex-row container mx-auto pt-[100px]'>
        <img src={logo} alt="" className='w-[80px] h-[40px] ml-[20px]'/>
        <div className='ml-[20px] xl:ml-[150px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
          <p className='text-[#FFFFFF] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer1')} 441241340102</p>
          <div>
            <p className='text-[#FFFFFF] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer2')}Murky Hill Offices Cow Lane, Middleton Tyas, Richmond, N</p>
            <p className='text-[#FFFFFF] roboto text-[14px] xl:text-[16px] font-normal'>Yorkshire, DL10 6R</p>
          </div>
          <p className='text-[#FFFFFF] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer3')} support@turkmill.com</p>
        </div>
        <div className='ml-[30px] xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
          <a href="/terms.pdf"><p className='text-[#FFFFFF] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer4')}</p></a>
          <a href="/privacy.pdf"><p className='text-[#FFFFFF] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer5')}</p></a>
          <a href="/aml.pdf"><p className='text-[#FFFFFF] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer6')}</p></a>
        </div>
      </div>
      <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[250px] pt-[40px]'>
        <p className='text-[#FFFFFF] roboto text-[12px] font-normal'>{t('footer7')}</p>
        <p className='text-[#FFFFFF] roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer8')}</p>
        <p className='flex justify-end pb-[100px] mt-[14px] roboto text-[16px] text-[#FFFFFF] pr-[30px] xl:pr-0'>Türk Mill © 2024. All rights reserved.</p>      
      </div>
    </div>
  );
}

export default Footer;