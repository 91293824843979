import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

import Button from "../Button";

import forex1 from '../../img/Products/forex1.png'
import forex2 from '../../img/Products/forex2.png'
import global from '../../img/Products/global.png'

function Switcher () {

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('forex');

    const renderContent = () => {
        switch(activeTab) {
          case 'forex':
            return  <div>
                        <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('products.14')}</p>
                        <p className="text-[#303030] pepsi text-[25px]">{t('products.15')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.16')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.17')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.18')}</p>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.19')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.20')}</p>
                        <div className='flex flex-col xl:flex-row gap-[35px] mt-[35px]'>
                            <img src={forex1} alt="" />
                            <div className='flex flex-col justify-between gap-[10px] xl:gap-0'>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.21')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.22')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.23')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.24')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.25')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.26')}</p>
                                </div>
                            </div>
                        </div>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.27')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.28')}</p>
                        <div className='flex flex-col xl:flex-row gap-[35px] mt-[35px]'>
                            <img src={forex2} alt="" />
                            <div className='flex flex-col justify-between gap-[10px] xl:gap-0'>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.29')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.30')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.31')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.32')}</p>
                                </div>
                                <Button/>
                            </div>
                        </div>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.33')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.34')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.35')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.36')}</p>
                    </div>;
          case 'global-markets':
            return <div>
                        <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('products.37')}</p>
                        <p className="text-[#303030] pepsi text-[25px]">{t('products.38')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.39')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.40')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.41')}</p>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.42')}</p>
                        <div className='flex flex-col xl:flex-row gap-[35px] mt-[35px]'>
                            <img src={global} alt="" />
                            <div className='flex flex-col justify-between gap-[10px] xl:gap-0'>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.43')}</p>
                                </div>
                                <div className='flex items-center gap-[25px]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                        <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                                    </svg>
                                    <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.44')}</p>
                                </div>
                                <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.45')}</p>
                                <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.46')}</p>
                            </div>
                        </div>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.47')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.48')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.49')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.50')}</p>
                   </div>;
          case 'stocks':
            return <div>
                        <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('products.51')}</p>
                        <p className="text-[#303030] pepsi text-[25px]">{t('products.52')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.53')}</p>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.54')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.55')}</p>
                        <div className='flex items-center gap-[25px] mt-[30px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.56')}</p>
                        </div>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.57')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.58')}</p>
                        <div className='flex items-center gap-[25px] mt-[30px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('products.59')}</p>
                        </div>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.60')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.61')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.62')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.63')}</p>
                   </div>;
          case 'metals-commodities':
            return <div>
                        <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('products.64')}</p>
                        <p className="text-[#303030] pepsi text-[25px]">{t('products.65')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.66')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.67')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.68')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.69')}</p>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.70')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.71')}</p>
                        <p className="text-[#303030] pepsi text-[25px] mt-[50px]">{t('products.72')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('products.73')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.74')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('products.75')}</p>
                   </div>;
          default:
            return null;
        }
      };
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
                <div className="xl:w-[279px] flex flex-col gap-[40px]">
                    <div className='flex flex-col gap-[15px]'>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'forex' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('forex')}>{t('products.7')}</button>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'global-markets' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('global-markets')}>{t('products.8')}</button>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'stocks' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('stocks')}>{t('products.9')}</button>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'metals-commodities' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('metals-commodities')}>{t('products.10')}</button>
                    </div>
                    <div className='h-[321px] rounded-[30px] bg-[#2050A0]  flex-col items-center justify-center gap-[10px] hidden xl:flex'>
                        <p className='text-white inter font-[700] text-[24px]'>{t('products.11')}</p>
                        <p className='text-white inter font-[700] text-[50px]'>{t('products.12')}</p>
                        <p className='text-white inter font-[700] text-[24px]'>{t('products.13')}</p>
                    </div>
                </div>
                <div className='max-w-[900px] mt-[30px] xl:mt-0'>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default Switcher;