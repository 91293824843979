import React from "react";

import { useTranslation } from "react-i18next";

import self1 from '../../img/self(1).png'
import self2 from '../../img/self(2).png'
import self3 from '../../img/self(3).png'

import self1mob from '../../img/self(1)Mobile.png'

function SelfDirected () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
               <div className="relative rounded-[30px] overflow-hidden">
                    <img src={self3} alt="" />
                    <div className="absolute left-[30px] bottom-[30px]">
                        <p className="inter text-[16px] xl:text-[25px] font-[800] text-white">{t('home.25')}</p>
                        <p className="inter text-[14px] xl:text-[16px] text-white mt-[15px] max-w-[381px] font-[200]">{t('home.26')}</p>
                    </div>
               </div>
               <div className="flex flex-col justify-between">
                <div className="xl:w-[471px] h-[180px] xl:h-[265px] bg-[#FF2C57] rounded-[30px] relative mt-[20px] xl:mt-0">
                    <div className="absolute left-[30px] top-[30px]">
                        <p className="inter text-[16px] xl:text-[25px] font-[800] text-white">{t('home.27')}</p>
                        <p className="inter text-[14px] xl:text-[16px] text-white mt-[15px] max-w-[381px] font-[200]">{t('home.28')}</p>
                    </div>
                </div>
                <div className="relative overflow-hidden rounded-[30px] mt-[20px] xl:mt-0">
                    <img src={self2} alt="" />
                    <div className="absolute left-[30px] top-[30px]">
                        <p className="inter text-[16px] xl:text-[25px] font-[800] text-black">{t('home.29')}</p>
                        <p className="inter text-[14px] xl:text-[16px] text-black mt-[15px] max-w-[381px] font-[200]">{t('home.30')}</p>
                    </div>
                </div>
               </div>
               <div className="flex flex-col justify-between">
                <div className="relative overflow-hidden rounded-[30px] mt-[20px] xl:mt-0">
                    <img src={self1} alt="" className="hidden xl:block"/>
                    <img src={self1mob} alt="" className="block xl:hidden h-full w-full"/>
                    <div className="absolute left-[30px] top-[30px]">
                        <p className="inter text-[16px] xl:text-[25px] font-[800] text-black">{t('home.31')}</p>
                        <p className="inter text-[14px] xl:text-[16px] text-black mt-[15px] max-w-[381px] font-[200]">{t('home.32')}</p>
                    </div>
                </div>
                <a href="https://user.turkmill.net">
                    <div className="w-[209px] h-[100px] bg-[#2050A0] rounded-[30px] xl:flex  items-center justify-center hidden">
                        <p className="text-white inter text-[16px] font-[700]">{t('home.7')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                            <rect width="35" height="35" rx="17.5" fill="#2050A0"/>
                            <path d="M7.29163 17.5H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </a>
               </div>
            </div>
        </div>
    );
}

export default SelfDirected;