import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from "../Button";

const data = [
    {
        title: 'accounts.5',
        text1: '1:2000',
        text2: '30%',
        text3: '10',
        text4: '$ 500',
        text5: '$ 10',
        text6: 'Yes',
        text7: '1',
    },
    {
        title: 'accounts.6',
        text1: '1:1000',
        text2: '30%',
        text3: '20',
        text4: '$ 1000',
        text5: '$ 1000',
        text6: 'Yes',
        text7: '10',
    },
    {
        title: 'accounts.7',
        text1: '1:100',
        text2: '10%',
        text3: '30',
        text4: '$ 2500',
        text5: '$ 10000',
        text6: 'Yes',
        text7: '20',
    },
    {
        title: 'accounts.8',
        text1: '1:200',
        text2: '10%',
        text3: '100',
        text4: '$ 1500',
        text5: '$ 5000',
        text6: 'Yes',
        text7: '100',
    },
]

function NonStop () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] gap-[30px] xl:gap-0">
                <div className="xl:w-[205px] h-[113px] rounded-[15px] bg-[#FF2C57] relative flex items-center justify-center px-[20px]">
                    <div className="w-[47px] h-[47px] rounded-full bg-white absolute right-[-10px] top-[-20px] flex items-center justify-center">
                        <p className="text-[#303030] open-sans font-[700] text-[23px]">1</p>
                    </div>
                    <p className="text-white inter text-[14px] text-center font-[300]">{t('accounts.29')}</p>
                </div>
                <div className="xl:w-[205px] h-[113px] rounded-[15px] bg-[#2050A0] relative flex items-center justify-center px-[20px]">
                    <div className="w-[47px] h-[47px] rounded-full bg-white absolute right-[-10px] top-[-20px] flex items-center justify-center">
                        <p className="text-[#303030] open-sans font-[700] text-[23px]">2</p>
                    </div>
                    <p className="text-white inter text-[14px] text-center font-[300]">{t('accounts.30')}</p>
                </div>
                <div className="xl:w-[205px] h-[113px] rounded-[15px] bg-[#FF2C57] relative flex items-center justify-center px-[20px]">
                    <div className="w-[47px] h-[47px] rounded-full bg-white absolute right-[-10px] top-[-20px] flex items-center justify-center">
                        <p className="text-[#303030] open-sans font-[700] text-[23px]">3</p>
                    </div>
                    <p className="text-white inter text-[14px] text-center font-[300]">{t('accounts.31')}</p>
                </div>
                <div className="xl:w-[205px] h-[113px] rounded-[15px] bg-[#2050A0] relative flex items-center justify-center px-[20px]">
                    <div className="w-[47px] h-[47px] rounded-full bg-white absolute right-[-10px] top-[-20px] flex items-center justify-center">
                        <p className="text-[#303030] open-sans font-[700] text-[23px]">4</p>
                    </div>
                    <p className="text-white inter text-[14px] text-center font-[300]">{t('accounts.32')}</p>
                </div>
                <div className="xl:w-[205px] h-[113px] rounded-[15px] bg-[#FF2C57] relative flex items-center justify-center px-[20px]">
                    <div className="w-[47px] h-[47px] rounded-full bg-white absolute right-[-10px] top-[-20px] flex items-center justify-center">
                        <p className="text-[#303030] open-sans font-[700] text-[23px]">5</p>
                    </div>
                    <p className="text-white inter text-[14px] text-center font-[300]">{t('accounts.33')}</p>
                </div>
            </div>
        </div>
    );
}

export default NonStop;