import React, { useEffect } from "react";

import Main from '../components/Accounts/Main'
import WhyShould from "../components/Accounts/WhyShould";
import NonStop from "../components/Accounts/NonStop";

function Accounts () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <WhyShould/>
            <NonStop/>
        </div>
    );
}

export default Accounts;