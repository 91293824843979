import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

import Button from "../Button";

import switcher2 from '../../img/AboutUs/m-asset.png'

function Swicther () {

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('forex');

    const renderContent = () => {
        switch(activeTab) {
          case 'forex':
            return <div>
                        <p className="text-[#303030] pepsi text-[25px]">{t('aboutus.5')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('aboutus.7')}</p>
                        <div className='flex items-start gap-[25px] mt-[30px]'>
                            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('aboutus.8')}</p>
                        </div>
                        <div className='flex items-start gap-[25px] mt-[30px]'>
                            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('aboutus.9')}</p>
                        </div>
                        <div className='flex items-start gap-[25px] mt-[30px]'>
                            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('aboutus.10')}</p>
                        </div>
                        <div className='flex items-start gap-[25px] mt-[30px]'>
                            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('aboutus.11')}</p>
                        </div>
                        <div className='flex items-start gap-[25px] mt-[30px]'>
                            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('aboutus.12')}</p>
                        </div>
                        <div className='flex items-start gap-[25px] mt-[30px]'>
                            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('aboutus.13')}</p>
                        </div>
                        <div className='flex items-start gap-[25px] mt-[30px]'>
                            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17789L11.0337 20.2747Z" fill="#2050A0"/>
                            </svg>
                            <p className='text-[#303030] inter text-[14px] xl:text-[17px] font-[300]'>{t('aboutus.14')}</p>
                        </div>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('aboutus.15')}</p>
                   </div>;
          case 'global-markets':
            return <div className=''>
                        <p className="text-[#303030] pepsi text-[25px]">{t('aboutus.6')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('aboutus.16')}</p>
                   </div>;
          default:
            return null;
        }
      };
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] gap-[100px]">
                <div className="xl:w-[279px] flex flex-col gap-[40px]">
                    <div className='flex flex-col gap-[15px]'>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'forex' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('forex')}>{t('aboutus.5')}</button>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'global-markets' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('global-markets')}>{t('aboutus.6')}</button>
                    </div>
                    <img src={switcher2} alt="" className='mt-[40px] hidden xl:block'/>
                </div>
                <div className='max-w-[900px] mt-[30px] xl:mt-0 flex flex-col mr-auto'>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default Swicther;