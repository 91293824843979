import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/main.png'
import check from '../../img/check.png'

import Fade from 'react-reveal/Fade'
import Button from "../Button";

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto relative">
                <div className="mt-[70px]">
                    <div className="flex flex-col">
                        <p className="text-[#FF2C57] text-[115px] xl:text-[180px] pepsi uppercase leading-[1] tracking-wider">{t('home.1')}</p>
                        <p className="ml-auto text-[#303030] font-[700] text-[35px] xl:text-[70px] inter leading-[1] mt-[20px]">{t('home.2')}</p>
                        <p className="ml-auto max-w-[210px] inter font-[200] xl:text-[23px] text-right text-[#303030] mt-[15px]"><Trans>{t('home.3')}</Trans></p>
                        <div className="flex items-center gap-[20px]">
                            <img src={check} alt="" />
                            <p className="inter text-[16px] xl:text-[25px] font-[700] text-[#303030]">{t('home.4')}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[20px]">
                            <img src={check} alt="" />
                            <p className="inter text-[16px] xl:text-[25px] font-[700] text-[#303030]">{t('home.5')}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[20px]">
                            <img src={check} alt="" />
                            <p className="inter text-[16px] xl:text-[25px] font-[700] text-[#303030]">{t('home.6')}</p>
                        </div>
                        <Button className="mt-[50px]"/>
                    </div>    
                </div>
                <div className="mt-[50px]">
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>   
                </div>
            </div>
        </div>
    );
}

export default Main;