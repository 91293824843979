import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import MobileMenu from "./MobileMenu";

function Header() {

    const { t } = useTranslation();

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="flex justify-center bg-[#F6F6F6] h-[100px]">
            <div className="hidden fixed w-full h-[60px] mt-[20px] bg-[#fff] z-[999] mx-auto xl:flex items-center max-w-[1280px] rounded-[80px]">
                <div className="flex gap-[60px] items-center">
                    <Link to="/"><img src={logo} alt="" className="ml-[20px] w-[80px]"/></Link>
                </div>           
                <div className="ml-auto flex items-center gap-[50px] mr-[20px]">
                    <Link to="/products"><p className="text-[16px] roboto text-[#313131]">{t('header.1')}</p></Link>
                    <Link to="/services"><p className="text-[16px] roboto text-[#313131]">{t('header.2')}</p></Link>
                    <Link to="/accounts"><p className="text-[16px] roboto text-[#313131]">{t('header.3')}</p></Link>
                    <Link to="/faqs"><p className="text-[16px] roboto text-[#313131]">{t('header.4')}</p></Link>
                    <Link to="/about"><p className="text-[16px] roboto text-[#313131]">{t('header.5')}</p></Link>
                    {/* <Dropdown
                        
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    /> */}
                    <a href="https://user.turkmill.net">
                        <div className="buttonheader cursor-pointer">
                            <p className="font-[700] text-[17px] roboto text-[#313131]">{t('header.6')}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="xl:hidden w-full flex fixed z-[999] h-[60px] items-center justify-between bg-[#FFFFFF] rounded-[40px] mt-[10px]">
                <Link to="/"><img src={logo} alt="" className="ml-[20px] w-[40px]"/></Link>
                <div className="flex items-center gap-[10px]">
                    {/* <Dropdown
                        
                        options={languages}
                        placeholder="EN"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    /> */}
                    <MobileMenu className="mr-[20px]"/>
                </div>      
            </div>
        </div>
    );
}

export default Header;